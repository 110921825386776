//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HeadNav from './component/head-nav/index.vue'
import LeftColumn from './component/left-column/1.0.1/index.vue'

export default {
    data() {
        return {}
    },
    methods: {},
    created() {
    },
    components: {HeadNav, LeftColumn}
}

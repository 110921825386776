export default {
  data() {
    return {
      nav_list: [],
    };
  },
  methods: {
    __data_bind() {
      const __this = this;
      const nav_list = __this.$route.meta.nav_list_for_admin_layout_head_nav || [];
      __this.nav_list = nav_list;
    },
  },
  watch: {
    '$route': function (pNewValue, pOldValue) {
      const __this = this;
      __this.__data_bind();
    },
  },
  mounted() {
    const __this = this;
    __this.__data_bind();
  },
}
